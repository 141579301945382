@import '../../styles/index';

.base {
  background-color: $neutral-0;
  box-shadow: 0px 0px 2px rgba($neutral-12, 0.08),
    0px 8px 48px rgba($neutral-12, 0.08);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 1.5rem 1.5rem;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 2;
  transform: translateY(calc(100% + 1.5rem));
  transition: transform $duration-default ease-in-out,
    opacity $duration-default ease-in-out;
  opacity: 0;

  @include media-from(sm) {
    border-radius: 0.75rem;
    padding: 1.5rem;
    bottom: auto;
    top: 1rem;
    left: 1rem;
    max-width: 25rem;
    transform: none;
    opacity: 1;
  }

  &.expanded {
    transform: none;
    opacity: 1;
  }
}

.label {
  color: $neutral-8;
  cursor: pointer;
  align-items: center;
  display: flex;
  font-size: $font-size-18;
  gap: 1rem;
  justify-content: space-between;
  line-height: 1.1;
  padding-left: 1.5rem;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &::before {
    --dot-width: 1rem;
    aspect-ratio: 1;
    background-color: $neutral-2;
    border: 2px solid $neutral-7;
    border-radius: $border-radius-full;
    box-sizing: border-box;
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: var(--dot-width);
  }

  .checkbox {
    > div {
      border-color: $neutral-7;

      &::before {
        background-color: $neutral-7;
      }
    }

    input:focus + div {
      border-color: $neutral-7;
    }
  }

  &.landscapeprotection,
  &.naturereserve,
  &.forest,
  &.waterprotection {
    &::before {
      border: none;
    }
  }

  &.selected {
    color: var(--accent--100);

    &::before {
      background-color: var(--accent--20);
      border-color: var(--accent--100);
    }

    .checkbox {
      > div {
        border-color: $neutral-7;

        &::before {
          background-color: var(--accent--100);
        }
      }

      input:focus + div {
        border-color: var(--accent--20);
      }
    }

    &.waterprotection,
    &.forest,
    &.naturereserve {
      &::before {
        background-color: var(--accent--60);
      }
    }
  }
}

.expand-toggle {
  @include media-from(sm) {
    display: none;
  }
}
