@import '../../styles/index';

.base {
  width: 8rem;
  position: relative;

  /* we can't use "filter: drop-shadow" because of a bug in Safari,
    so we create a basic fake shadow */
  &::before {
    content: '';
    position: absolute;
    box-shadow: $shadow-traffic-light;
    left: 8%;
    right: 9%;
    top: 7%;
    bottom: 9%;
    transform: skewY(4deg);
  }

  & > svg {
    position: relative;
  }
}
