@import '../../styles/index';

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: -1.56rem;
  transform: translateX(-50%);
  background-color: $neutral-0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: $shadow-standard;
  cursor: pointer;
  z-index: $index-map-toggle;

  & > i {
    color: $surrogate-9;
  }

  &.small {
    width: 2.5rem;
    height: 2.5rem;
    top: -1.5rem;
  }
}
