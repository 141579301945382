@import '../../styles/index';

.base {
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 4.25rem;
  background-color: $neutral-0;
  z-index: $index-global-header;
  padding: 0.625rem 1rem;
  box-shadow: $shadow-eng-map-menu-header;

  @include media-from(sm) {
    padding: 1rem 1.25rem;
    height: 6rem;
  }
}

.logo-wrapper {
  flex: 1;
  height: 26px;

  @include media-from(sm) {
    height: 34px;
  }
}

.logo-wrapper-netz {
  height: 38px;
  width: 49px;

  @include media-from(sm) {
    height: 64px;
    width: 83px;
  }
}

.logo {
  height: 100%;
}

.close-button {
  position: absolute;
  right: 1rem;
  top: calc(50%);
  padding: 0.65rem;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
  color: $surrogate-9;
  cursor: pointer;
  border-radius: 0.375rem;
  border: none;
  background-color: transparent;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(199, 18, 92, 0.05);
  }

  @include media-from(sm) {
    right: 1.5rem;
  }
}
