@import '../../styles/index';

.base {
  position: relative;
  overflow-y: scroll;
  flex: 1 1 auto;

  &::-webkit-scrollbar {
    width: 6px;
    background: rgba($surrogate-9, 10%);
  }

  &::-webkit-scrollbar-thumb {
    background: $surrogate-9;
    border-radius: 0;
  }
}
