@import '../../styles/index';

.base {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $neutral-0;
  padding: 1.5rem 1rem;
  z-index: $index-map-details;

  @include media-from(sm) {
    box-shadow: $shadow-eng-map-detail;
    height: 100%;
    padding: 2rem 1.5rem;
  }
}

.title {
  font-size: $font-size-20;
  font-weight: $font-weight-medium;
  line-height: 1.5;
  padding-right: 4rem;
  margin: 0 0 0.2rem 0;

  @include media-from(sm) {
    padding: 0;
    font-size: $font-size-24;
  }
}

.services {
  font-size: $font-size-16;
  font-weight: $font-weight-light;
  line-height: 22px;
  margin: 0 0 1rem;

  @include media-from(sm) {
    font-size: $font-size-18;
    margin: 0 0 2rem;
  }
}

.details p {
  display: flex;
  align-items: center;
  font-size: $font-size-16;
  font-weight: $font-weight-light;
  margin: 0 0 0.5rem;
  line-height: 1.5rem;
  color: $neutral-12;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: $surrogate-9;
    }
  }

  .icon {
    color: $surrogate-9;
    margin-right: 0.5rem;
  }

  @include media-from(sm) {
    font-size: $font-size-16;
    margin: 0 0 1rem;
  }
}

.clickable {
  display: flex;
  align-items: center;
  color: inherit;

  &:hover {
    color: $surrogate-9;
    text-decoration: underline;
  }
}

.toggleMobile {
  transform: none !important; // @todo: must be refactored after react static
  right: 1.5rem;
  left: auto !important; // @todo: must be refactored after react static

  @include media-from(sm) {
    display: none !important; // @todo: must be refactored after react static
  }
}

.toggleDesktop {
  display: none;
  color: $surrogate-9;
  font-weight: $font-weight-medium;
  font-size: $font-size-18;
  margin-bottom: 1.5rem;
  cursor: pointer;

  .icon {
    margin-right: 0.5rem;
  }

  @include media-from(sm) {
    display: flex;
    align-items: center;
  }
}
