@import '../../styles/index';

@mixin saving-color-theme($text-color, $divider-color) {
  color: $text-color;

  .saving-divider {
    background-color: $divider-color;
  }
}

.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @include media-from(sm) {
    flex-direction: row;
    gap: 2.5rem;
  }

  .traffic-light {
    width: 6.56rem;

    @include media-from(sm) {
      width: 7.5rem;
    }
  }

  .infobox {
    flex: 1 1 auto;

    @include media-from(sm) {
      padding: 1.625rem 1.625rem;
    }
  }

  .headline {
    font-size: $font-size-22;
    font-weight: $font-weight-bold;
    margin-bottom: 0.625rem;

    @include media-from(sm) {
      font-size: $font-size-24;
    }
  }

  .description {
    margin: 0;
    font-weight: $font-weight-light;
    font-size: $font-size-18;
    line-height: 1.55;

    @include media-from(sm) {
      font-size: $font-size-20;
      line-height: 1.6;
    }
  }

  .link {
    font-size: $font-size-20;
    margin-top: 0.75rem;
  }

  .traffic-light-outer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-from(sm) {
      flex: 0 0 30%;
      max-width: 20rem;
    }
  }

  .traffic-light-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .saving {
    font-size: $font-size-32;

    .saving-amount {
      margin-right: 0.3125rem;
      font-size: $font-size-72;
      line-height: 1;
    }

    .saving-subtext {
      display: block;
      line-height: 1.6;
      margin-bottom: 1rem;
      margin-top: -0.4rem;
      font-size: $font-size-20;
      font-weight: $font-weight-medium;
    }

    .saving-divider {
      height: 0.1875rem;
      width: 100%;
      max-width: 4rem;
      margin: 0;
    }
  }

  &.with-saving {
    .traffic-light-outer-wrapper {
      @include media-from(sm) {
        flex: 0 0 22.375rem;
        justify-content: right;
        max-width: none;
      }
    }
  }

  .red {
    @include saving-color-theme($red-9, $red-4);
  }

  .green {
    @include saving-color-theme($green-9, $green-4);
  }

  .yellow {
    @include saving-color-theme($yellow-9, $yellow-4);
  }
}
