@import '../../styles/index';

.base {
  position: relative;
  margin: 0;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid $surrogate-4;

  @include media-from(sm) {
    padding: 1.5rem;
  }
}

.title {
  display: none;
  font-size: $font-size-24;
  font-weight: $font-weight-medium;
  margin: 0 0 1rem;
  line-height: 1.75rem;

  @include media-from('sm') {
    display: block;
  }
}
